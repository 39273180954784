<template>
  <static-fullscreen-card @sendHeight="setContentHeight">
    <template v-slot:header></template>
    <template v-slot:title>
      <v-btn-toggle v-model="typeView" mandatory borderless dense>
        <v-btn :value="0" color="#d0bf9c">
          <span class="hidden-lg-and-down">Информация</span>
          <v-icon :right1="$vuetify.breakpoint.lgAndUp"> mdi-folder-information-outline </v-icon>
        </v-btn>
        <v-btn :value="1" class="ml-1" color="#d0bf9c">
          <span class="hidden-lg-and-down">Тестирование</span>
          <v-icon :right1="$vuetify.breakpoint.lgAndUp"> mdi-ab-testing </v-icon>
        </v-btn>
        <v-btn :value="2" class="ml-1" color="#d0bf9c">
          <span class="hidden-lg-and-down">Инструкции</span>
          <v-icon :right1="$vuetify.breakpoint.lgAndUp"> mdi-map </v-icon>
        </v-btn>
      </v-btn-toggle>
    </template>
    <template v-slot:actions>
      <v-btn color="primary" @click="showVideoDialog = true"><btn-title icon="fas fa-video">+Видео</btn-title> </v-btn>
      <v-btn color="primary" @click="$refs['table' + typeView].updateData()">
        <btn-title icon="fas fa-sync-alt">Обновить</btn-title>
      </v-btn>
      <v-btn color="primary" @click="createNew()" title="Создать элемент"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn></template
    >
    <portal to="v-main">
      <video-dialog v-if="showVideoDialog" v-model="showVideoDialog" />
      <edit-dialog v-if="typeView == 0 && showEditDialog" v-model="showEditDialog" :id="idEdit" />
      <edit-test-dialog v-if="typeView == 1" v-model="showEditDialog" :id="idEdit" />
      <edit-group-dialog v-if="typeView == 2" v-model="showEditDialog" :id="idEdit" :parent_id="currentDeep[0]" />
    </portal>
    <v-tabs-items v-model="typeView" class="transparent" touchless>
      <v-tab-item :style="{ height: $root.windowSize.contentHeight - 16 + 'px' }">
        <a-table-f-api
          v-if="typeView == 0"
          ref="table0"
          :api="url"
          :model="model"
          :useQuery="false"
          :defaults="{ sort: { key: 'id', order: 'DESC' } }"
          @click="onClickRow($event)"
        />
      </v-tab-item>
      <v-tab-item :style="{ height: $root.windowSize.contentHeight - 16 + 'px' }">
        <a-table-f-api
          v-if="typeView == 1"
          ref="table1"
          :api="'/mechti/information_test'"
          :model="modelTest"
          :useQuery="false"
          :defaults="{ sort: { key: 'id', order: 'DESC' } }"
          @click="onClickRow($event)"
        />
      </v-tab-item>
      <v-tab-item :style="{ height: $root.windowSize.contentHeight - 16 + 'px' }">
        <div v-if="typeView == 2" class="row pt-4" style="height: 100%">
          <v-col cols="12" sm="3" class="py-0 px-1" style="height: 100%">
            <v-treeview dense open-all :items="treeList" activatable :active="currentDeep" @update:active="onTreeClick"></v-treeview>
          </v-col>
          <v-col cols="12" sm="9" class="py-0 px-1 pr-3" style="height: 100%">
            <a-table-f-api
              ref="table2"
              :api="'/mechti/information_group'"
              :model="modelGroup"
              :useQuery="false"
              :defaults="{ sort: { key: 'id', order: 'DESC' }, filters: filters }"
              @click="onClickRow($event)"
            >
              <template v-slot:item.is_parent="{ row }">
                <v-icon v-if="row.is_parent == 0"> mdi-file </v-icon>
                <v-icon v-if="row.is_parent == 1"> mdi-folder </v-icon>
              </template>
            </a-table-f-api>
          </v-col>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </static-fullscreen-card>
</template>

<script>
import { autoHeightBlock, getAccess, keyDetect } from "@/components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, keyDetect],
  components: {
    videoDialog: () => import("./dialogs/infoVideoDialog"),
    editDialog: () => import("./dialogs/infoDialog"),
    editTestDialog: () => import("./dialogs/testBiletDialog"),
    editGroupDialog: () => import("./dialogs/infoGroupDialog"),
  },
  data() {
    return {
      idEdit: 0,
      typeView: 0,
      showEditDialog: false,
      showVideoDialog: false,
      currentDeep: [-1],
      title: "",
      model: this.$store.getters["config/get"].models.info.list,
      modelGroup: this.$store.getters["config/get"].models.info.group.list,
      modelVideo: [{ name: "videos", title: "#", type: "videos", sortable: true, width: 50 }],
      modelTest: [
        { name: "id", title: "#", type: "id", sortable: true, width: 50 },
        { name: "name", title: "Название", type: "string", sortable: false, width: 120, formula: '((d) => {return d.data?.name || "";})' },
        { name: "createdon", title: "Дата создания", type: "datetime", sortable: true, width: 120 },
        { name: "status", title: "Статус", type: "select", options: "status_active", sortable: true, width: 120 },
      ],
      url: "/mechti/information",

      tree: null,
      filters: null,
    };
  },
  created() {
    this.$root.title = "Информация";
    this.genTreeList();
  },
  computed: {
    treeList() {
      return this.tree || [];
    },
    defaults() {
      return {
        sort: { key: "id", order: "DESC" },
        filters: this.filters,
      };
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs["table" + this.typeView].updateData();
      }
    },
  },
  methods: {
    onTreeClick(e) {
      let c = e[0] === undefined ? -1 : e[0];
      console.log(e, c);
      this.currentDeep = [c];
      this.filters = c == -1 ? {} : { parent_id: c };
    },
    async genTreeList() {
      if (this.tree) return;
      this.tree = [];
      let convertToTree = (data, parent_id = 0) => {
        const result = [];
        for (const item of data) {
          if (item.parent_id == parent_id) {
            const children = convertToTree(data, item.id);
            if (children.length > 0) {
              item.children = children;
            }
            result.push(item);
          }
        }
        return result;
      };
      let res = [];
      let tree = [];
      let api = "/mechti/information_group";
      let resp = await this.$axios.get(api, { params: { filters: { is_parent: 1 } } });
      if (resp.data.status == "ok") {
        res = resp.data.data;
        res = [
          { id: -1, name: "Все", parent_id: null },
          { id: 0, name: "(0) База знаний", parent_id: -1 },
          ...res.map((r) => {
            return { id: r.id, name: `(${r.id}) ${r.name}`, parent_id: r.parent_id };
          }),
        ];
        console.log(res);
        tree = convertToTree(res, -1);
        tree = [{ id: -1, name: "Все", parent_id: null }, ...tree];
      }
      this.tree = tree;
    },
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    addVideo() {
      console.log("add video");
      this.showVideoDialog = true;
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
<style lang="scss">
.v-treeview-node__label {
  white-space: normal !important;
}
</style>
